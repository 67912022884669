// 公司团队
<template>
  <div class="company-team">
    <section v-for="team in teams" :key="team.id">
      <h3 class="organizationChart-title">{{team.title}}</h3>
      <img class="organizationChart-img" :src="require('@/assets/images/gsgk-img1.png')" />
      <el-row class="team-list" :gutter="30">
        <el-col class="team-item" :span="6" v-for="item in team.company_team" :key="item.id">
          <div class="team-img">
            <el-image :src="$base+item.image" :alt="item.name"></el-image>
          </div>

          <div class="team-info flex-center flex-warp">
            <div>
              <p class="team-name">{{item.name}}</p>
              <p class="team-postion" v-for="(item,index) in item.postion.split(',')" :key="index">{{item}}</p>
            </div>

          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import { getTeam } from '@/api'
export default {
  name: 'CompanyTeam',
  data () {
    return {
      teams: []
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getTeam()
      this.teams = data
    }
  },
};
</script>

<style scoped lang="less">
section {
  overflow: hidden;
}
.organizationChart-title {
  text-align: center;
  font-size: 24px;
  color: #e6ac44;
  font-weight: bold;
}
.organizationChart-img {
  width: 100%;
  margin: 36px 0 50px -4px;
}
.team-list {
  width: 100%;
  height: 100%;
  margin: 0 auto !important;
  .team-item {
    margin-bottom: 30px;
    .el-image {
      display: block;
    }
    .team-info {
      height: 72px;
      background-color: #fff;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;

      p {
        width: 100%;
        text-align: center;
      }
      .team-name {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: bold;
      }
      .team-postion {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
  .team-iten-def {
    height: 0;
    background-color: transparent;
  }
}
</style>
